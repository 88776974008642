import React, {useContext} from "react";
import styles from "./Footer.module.scss";
import Discord from "../../img/discord.png";
import TikTok from "../../img/tiktok.png";
import X from "../../img/x.png";
import Tg from "../../img/tg.png";
// import Facebook from "../../img/facebook.png";
import SendFeedbackButton from "../../img/feedback.png";
import BackButton from "../../img/back_btn.png";
import {useLocation} from "react-router-dom";
import {CurrentMenuStepContext} from "../Main/Main";
import {Link as NativeLink} from "react-router-dom";
import styled from "styled-components";
import BGButton from "../../img/bg-button.png";

const Link = styled(NativeLink)`
  text-decoration: none;
`
const StyledLink = styled(Link)`
  background-image: url(${BGButton});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  width: 274px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;

  color: #FFF;

  font-family: November;
  font-size: 24px;
  font-style: normal;
  font-weight: 5;
  line-height: normal;

  transition: 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    transform: scale(1.1);
  }

  @media screen and (max-width: 1330px) {
    width: 200px;
    height: 43.79px;

    font-size: 20px;
  }

  @media screen and (max-width: 991px) {
    display: none;
  }

`

export const Footer = () => {
  const { pathname } = useLocation();

  const {selectedStep} = useContext(CurrentMenuStepContext);

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.footer__menu}>
          <a href="https://discord.gg/J4uA3P2p2g">
            <img src={Discord} alt="discord" />
          </a>
          <a href="https://www.tiktok.com/@heroguys.io?_t=8m2MyE75liP&_r=1">
            <img src={TikTok} alt="tiktok" />
          </a>
          <a href="https://twitter.com/heroguys_io">
            <img src={X} alt="x" />
          </a>
          <a href="https://t.me/heroguys_io">
            <img src={Tg} alt="tg" />
          </a>
        </div>
        {pathname === "/" && (
            <>
              {selectedStep === 3 && (
                  <a
                      href="https://discord.gg/J4uA3P2p2g"
                      className={styles.footer__right}
                  >
                    <img
                        className={styles.footer__right_button}
                        src={SendFeedbackButton}
                        alt="Send feedback"
                    />
                    <div className={styles.footer__create}>
                      {/* Create by Ex2.team */}
                    </div>
                  </a>
              )}

              {selectedStep !== 3 && (
                  <StyledLink to={"/leaderboard" }>
                    Leaderboard
                  </StyledLink>
              )}

              {/*{selectedStep !== 3 && (
                  <a
                      href="/leaderboard"
                      className={styles.footer__right}
                  >
                    <img
                        className={styles.footer__right_button}
                        src={BGButton}
                        alt="Send feedback"
                    />
                    <div className={styles.footer__create}>
                       Create by Ex2.team
                    </div>
                  </a>
              )}*/}
            </>
        )}
        {pathname.startsWith('/leaderboard') && (
            <StyledLink to={"/" }>
              Back
            </StyledLink>
            /*<a
                href="/"
                className={styles.footer__right}
            >
              <img
                  className={styles.footer__right_button}
                  src={BackButton}
                  alt="Back"
                  style={{width: "135px"}}
              />
            </a>*/
        )}

      </div>
    </footer>
  );
};
