import React, { useEffect, useState } from "react";
import styles from "./Header.module.scss";
import Logo from "../../img/logo.svg";
// import Download from "../../img/download__btn.png";
import Play from "../../img/play.png";
import UserIcon from "../../img/user.svg";
import { deployLink } from "../../deploy";

export const Header = () => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <header className={styles.header}>
      <div className={styles.header__container}>
        <img src={Logo} alt="logo" className={styles.header__logo} />
        <div className={styles.header__menu}>
          {/* <div className={styles.header__menu_link}>Register</div> */}
          {/* <div className={styles.header__menu_link}>Login</div> */}
          {windowWidth < 768 ? (
            <a
              href="https://play.google.com/store/apps/details?id=com.EXTeam.HeroGuys"
              className={styles.header__menu_button}
            >
              <img src={Play} alt="play" />
            </a>
          ) : (
            <a href={deployLink} className={styles.header__menu_button}>
              <img src={Play} alt="play" />
            </a>
          )}
        </div>
      </div>
      {/* <button className={styles.header__mobile_menu}>
        <img src={UserIcon} alt="user" />
      </button> */}
    </header>
  );
};
