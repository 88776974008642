import React, {createContext, Suspense, useState} from "react";
import styles from "./Main.module.scss";
import { Header } from "../Header/Header";
import  Menu  from "../Menu/Menu";
import { Footer } from "../Footer/Footer";
import { Modal } from "../Modal/Modal";
import {routes, useRouterConfig} from "../../RouteDefinitions";
import {Route, Routes} from "react-router-dom";

export const CurrentMenuStepContext = createContext(null);

export const Main = () => {

  const routerConfig = useRouterConfig()

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedStep, setSelectedStep] = useState(2);

  return (
      <CurrentMenuStepContext.Provider value={{
          selectedStep,
          setSelectedStep
      }}>
          <div className={styles.main}>
              <Header />
              <Suspense fallback={<div></div>}>
                  <Routes>
                      {routes.map((route) =>
                          <Route key={route.path} path={route.path} element={route.getElement(routerConfig)}>
                              {route.nestedPaths.map((nestedPath) => (
                                  <Route path={nestedPath} key={`${route.path}/${nestedPath}`}/>
                              ))}
                          </Route>
                      )}
                  </Routes>
              </Suspense>
              {/*<Menu />*/}
              <Footer />
              {isOpenModal && <Modal closeAction={() => setIsOpenModal(false)} />}
          </div>
      </CurrentMenuStepContext.Provider>
  );
};
