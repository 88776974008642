import React, {useContext, useState} from "react";
import styles from "./Menu.module.scss";
import AboutImage from "../../img/about.svg";
import ModesImage from "../../img/modes.svg";
import DownloadImage from "../../img/Download.svg";
import ContactsImage from "../../img/Contacts.svg";
import DownloadGoogle from "../../img/googleplay.svg";
import MessageIcon from "../../img/message.svg";
import PhoneIcon from "../../img/phone.svg";
import Discord from "../../img/discord_transparent.svg";
import TikTok from "../../img/tiktok_transparent.svg";
import X from "../../img/twitter_transparent.svg";
import Tg from "../../img/tg_transparent.svg";
// import Facebook from "../../img/facebook_tranparent.svg";
import ArrowIcon from "../../img/arrow.svg";
import "animate.css";

import cn from "classnames";
import {CurrentMenuStepContext} from "../Main/Main";

const textsForMenu = [
  "HERO Guys is a lively multiplayer game where players dash through vibrant obstacle courses, tackle intense arenas, and conquer exhilarating levels in a whimsical battle royale environment.",
  "Our game with customizable characters, treacherous traps, and a quest to outlast opponents, HERO Guys offers a thrilling and competitive experience where the ultimate goal is to be the last one standing.",
];

const colorCircle = ["#FF1644", "#2ED55B", "#F7931F", "#651FFF"];

const stepsText = {
  0: "About",
  1: "Game",
  2: "Download",
  3: "Contacts",
};

const Menu = () => {
  /*const [selectedStep, setSelectedStep] = useState(2);*/

  const {
    selectedStep,
    setSelectedStep
  } = useContext(CurrentMenuStepContext);

  const nextStep = () => {
    setSelectedStep((prevStep) => (prevStep + 1) % 4);
  };

  const prevStep = () => {
    setSelectedStep((prevStep) => (prevStep - 1 + 4) % 4);
  };

  return (
    <div>
      <div
        className={styles.circle}
        style={{ background: colorCircle[selectedStep] }}
      >
        <div className={styles.circle__main}>
          <img
            src={AboutImage}
            alt="about"
            className={cn(
              styles.circle__emoji,
              styles.circle__emoji_about,
              selectedStep === 0 ? styles.circle__emoji_active : "",
              "animate__animated",
              "animate__fadeIn"
            )}
          />
          <img
            src={ModesImage}
            alt="about"
            className={cn(
              styles.circle__emoji,
              styles.circle__emoji_modes,
              selectedStep === 1 ? styles.circle__emoji_active : "",
              "animate__animated",
              "animate__fadeIn"
            )}
          />
          <img
            src={DownloadImage}
            alt="about"
            className={cn(
              styles.circle__emoji,
              styles.circle__emoji_download,
              selectedStep === 2 ? styles.circle__emoji_active : "",
              "animate__animated",
              "animate__fadeIn"
            )}
          />
          <img
            src={ContactsImage}
            alt="about"
            className={cn(
              styles.circle__emoji,
              styles.circle__emoji_contacts,
              selectedStep === 3 ? styles.circle__emoji_active : "",
              "animate__animated",
              "animate__fadeIn"
            )}
          />
          <div className={styles.circle__main_control}>
            <button onClick={prevStep}>
              <img src={ArrowIcon} alt="prev step" />
            </button>
            <div className={styles.circle__main_control_current}>
              {stepsText[selectedStep]}
            </div>
            <button onClick={nextStep}>
              <img src={ArrowIcon} alt="next step" />
            </button>
          </div>
          <div
            className={cn(
              styles.circle__about,
              selectedStep === 0 ? styles.circle__about_active : ""
            )}
            onClick={() => setSelectedStep(0)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="78"
              height="106"
              viewBox="0 0 78 106"
              fill="none"
              className="animate__animated animate__rotateInDownLeft"
            >
              <path
                d="M25.951 97.9896L22.6928 105.201L6.85952 92.2848L6.35558 91.8737L6.08781 92.4663L5.58477 93.5797L0.621818 88.7537L6.02511 76.8209L33.6685 80.9662L30.3168 88.3836L26.8236 87.5846L26.4246 87.4933L26.2563 87.8664L23.0276 95.0247L22.8731 95.3672L23.1591 95.6109L25.951 97.9896ZM10.9289 84.741L9.05133 84.4348L10.5357 85.6246L17.1868 90.9557L17.6898 91.3589L17.9552 90.7714L19.7417 86.816L20.0102 86.2217L19.3665 86.1167L10.9289 84.741Z"
                stroke="white"
              />
              <path
                d="M38.6656 58.3247C39.2281 58.8382 39.7362 59.5048 40.1962 60.3356L40.4894 60.9452C41.4942 63.0341 41.6926 65.1202 41.0636 67.2279L41.0613 67.2356L41.0592 67.2435C40.8535 68.0239 40.5671 68.758 40.1808 69.4483L40.1786 69.4523C39.4842 70.7204 38.4841 71.5607 37.1617 72.014L36.6574 72.1869L36.8622 72.6791L37.4588 74.1126L34.4347 79.6335L16.2972 69.7266L15.858 69.4867L15.6185 69.9261L14.9991 71.0626L9.83925 66.3217L13.3073 59.9879L23.6528 65.6385L24.3072 64.9204L22.0445 61.5608L22.5653 60.6044C23.179 59.5058 23.7381 58.683 24.2407 58.1201C24.7424 57.5796 25.3934 57.0492 26.222 56.5319C27.6609 55.6495 29.1941 55.1613 30.8056 55.0798C32.4004 54.9992 34.0104 55.4006 35.6217 56.2667C36.9375 56.9874 37.9535 57.6744 38.6656 58.3247ZM27.8657 64.8534L27.8617 64.8609L27.8579 64.8684C27.8098 64.9646 27.761 65.0524 27.7099 65.1437L27.7001 65.1612C27.6534 65.2446 27.6026 65.3353 27.5566 65.4273C27.5218 65.4967 27.4716 65.5838 27.3987 65.7098C27.3287 65.8307 27.2442 65.977 27.1537 66.1465L27.1361 66.1794L27.1236 66.2146L26.8868 66.881L26.7446 67.2813L27.1166 67.4863L27.7404 67.83C28.7742 68.4011 29.6884 68.7727 30.4516 68.9157L30.4543 68.9161C31.246 69.0599 31.9808 68.9939 32.6324 68.6816C33.2556 68.3828 33.7708 67.9251 34.1061 67.2833C34.5823 66.4048 34.6412 65.5097 34.2269 64.6047C33.8482 63.7774 33.3326 63.173 32.6659 62.7888L32.6659 62.7887L32.6562 62.7834C32.1248 62.4927 31.5624 62.3645 30.9799 62.4207C30.402 62.4765 29.8374 62.7104 29.2897 63.0909L29.185 63.1636L29.1611 63.2113C28.7008 63.562 28.2623 64.1239 27.8657 64.8534Z"
                stroke="white"
              />
              <path
                d="M52.4376 49.2723L52.4374 49.2728C52.1729 50.0044 51.8041 50.7231 51.3249 51.4723C50.0102 53.5028 48.2096 54.8936 45.906 55.6749C44.6792 56.0691 43.5557 56.2492 42.5089 56.209L42.5089 56.2089L42.4995 56.2087C41.4809 56.1888 40.3173 55.9294 38.979 55.4296L38.0456 55.004C37.912 54.9134 37.7637 54.833 37.6319 54.7615C37.5941 54.741 37.5577 54.7213 37.5233 54.7022C37.4141 54.6416 37.3388 54.6013 37.2852 54.5726C37.2257 54.5408 37.193 54.5233 37.1704 54.5082L37.1704 54.5082L37.1643 54.5043C35.2108 53.2443 33.856 51.3721 33.0892 48.8492C32.4162 46.5245 32.6158 44.1996 33.7081 41.8309C33.9392 41.3486 34.1881 40.8728 34.4974 40.3786L34.4974 40.3786L34.4992 40.3757C34.5777 40.2483 34.6517 40.1347 34.7196 40.0304L34.7294 40.0153C34.7936 39.9166 34.858 39.8176 34.9086 39.7306C35.5652 38.7292 36.3154 37.9444 37.1556 37.365C38.0025 36.7811 39.091 36.3049 40.4227 35.8826C41.0824 35.6768 41.6823 35.5594 42.2277 35.5399L42.2388 35.5395L42.2499 35.5386C44.5589 35.3533 46.6223 35.8464 48.4612 37.0299C50.0219 38.0348 51.187 39.3422 51.9859 40.9583L52.1235 41.2368H52.1383C53.3329 43.862 53.4322 46.5309 52.4376 49.2723ZM39.536 44.1173H39.8805C39.8048 44.2506 39.7173 44.4324 39.6175 44.6716L39.614 44.6799L39.6109 44.6883C39.3204 45.4619 39.2661 46.2305 39.4423 46.9592L39.4442 46.9671L39.4463 46.9749C39.6513 47.717 40.09 48.3052 40.7552 48.7525C41.3942 49.2453 42.0741 49.5296 42.7835 49.5556C43.5001 49.5818 44.192 49.3425 44.8436 48.8846L44.8523 48.8785L44.8607 48.872C44.9064 48.8369 44.9528 48.8016 44.9993 48.7663C45.2131 48.6037 45.428 48.4403 45.5767 48.2919L45.2233 47.9381L45.5767 48.2919C45.7036 48.1651 45.8374 47.9725 45.9621 47.7858C46.4123 47.1114 46.5951 46.37 46.4951 45.5818C46.4172 44.7965 46.0832 44.1398 45.5458 43.577C45.2993 43.3071 45.0311 43.0893 44.7471 42.9002L44.7471 42.9001L44.7401 42.8956C44.0635 42.4612 43.3576 42.2534 42.6351 42.3157C41.9162 42.3777 41.2363 42.7023 40.5986 43.233L39.536 44.1173Z"
                stroke="white"
              />
              <path
                d="M65.0443 19.5541L69.4883 24.9433L65.3963 30.43L62.654 28.4041L61.9079 29.0262L63.7114 32.7084L61.6342 35.5138C61.6339 35.5143 61.6336 35.5147 61.6332 35.5152C60.9798 36.3847 60.1535 37.0138 59.1246 37.4097C57.7893 37.899 56.48 37.8436 55.1268 37.2673L55.1269 37.2672L55.1189 37.264C54.4399 36.9885 53.9963 36.7508 53.7718 36.5784L53.7719 36.5783L53.7655 36.5736L48.2982 32.5107L47.8989 32.214L47.6002 32.6118L46.8247 33.6444L42.7124 28.5154L46.8542 22.9495L54.3083 28.4971C54.3085 28.4972 54.3088 28.4974 54.309 28.4976C55.0867 29.0799 55.8617 29.4099 56.6164 29.3756C57.3938 29.3403 58.0409 28.9248 58.5568 28.2501L58.5568 28.2501L58.5609 28.2447L59.6156 26.8259L59.914 26.4245L59.5124 26.1262L49.8411 18.9442L53.8926 13.5118L63.5656 20.6951L63.9648 20.9916L64.2635 20.5939L65.0443 19.5541Z"
                stroke="white"
              />
              <path
                d="M63.8369 1.5022L67.8533 7.02442L67.2441 7.76638L66.9281 8.15126L67.3116 8.46882L76.7416 16.2755L72.2137 21.7395L62.7834 13.9325L62.399 13.6143L62.08 13.9981L60.7043 15.6534L56.6838 10.1254L56.9722 9.77978L57.294 9.39423L56.907 9.07417L55.0789 7.56196L58.2655 1.00881L61.4127 3.6311L61.7968 3.95121L62.1169 3.56699L63.8369 1.5022Z"
                stroke="white"
              />
            </svg>
          </div>
          <div
            className={cn(
              styles.circle__modes,
              selectedStep === 1 ? styles.circle__about_active : ""
            )}
            onClick={() => setSelectedStep(1)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="229"
              height="111"
              viewBox="0 0 229 111"
              fill="none"
              className="animate__animated animate__rotateInDownLeft"
            >
              <path
                d="M27.5228 86.9953C28.5991 88.973 29.2879 90.5852 29.6323 91.8105C29.9551 93.0358 30.0843 94.4976 29.9767 96.1958C29.9121 97.2061 29.7399 98.1949 29.4816 99.1193C29.0942 100.474 28.5776 101.699 27.9103 102.774C27.2645 103.849 26.4251 104.837 25.4134 105.697C24.9399 106.106 24.4233 106.493 23.8637 106.88C22.0986 108.148 20.2475 108.965 18.3533 109.33C17.4708 109.502 16.6529 109.61 15.878 109.631C15.1031 109.631 14.2637 109.588 13.3596 109.459C11.4439 109.137 9.78653 108.599 8.40895 107.825C7.03137 107.051 5.67531 105.934 4.38383 104.429C2.59728 102.365 1.52105 100.022 1.17666 97.4211C1.04751 96.5612 1.02598 95.7228 1.09056 94.8845C1.15513 93.6162 1.30581 92.4554 1.5641 91.4236C1.8224 90.3917 2.18832 89.4244 2.66186 88.5215C3.1354 87.6187 3.652 86.8018 4.21164 86.0709C4.77128 85.3401 5.35244 84.7167 5.97666 84.2008C7.24661 83.1044 8.60267 82.3951 10.0448 82.1156L10.1309 81.3847L16.8251 79.837L16.4592 88.9085L15.9211 89.016L15.1462 89.2309C14.3497 89.4674 13.704 89.7253 13.1874 90.0478C12.6708 90.3702 12.2188 90.6497 11.8529 90.9721C10.9704 91.7245 10.3892 92.6274 10.0879 93.6377C9.78652 94.648 9.78652 95.7014 10.0879 96.7977C10.174 97.1416 10.2385 97.3566 10.2816 97.4426C10.4107 97.808 10.626 98.1305 10.9058 98.4529C11.0134 98.6249 11.2072 98.8398 11.4439 99.1193C12.4341 100.259 13.6825 100.86 15.2323 100.946C16.287 101.032 17.191 100.86 17.9444 100.474C18.6977 100.087 19.3004 99.6782 19.7524 99.2913C20.5058 98.6249 21.0009 97.8295 21.2161 96.9052C21.2592 96.6472 21.3022 96.4107 21.3022 96.1528C21.3022 95.9163 21.3022 95.6154 21.3022 95.2929L17.9013 98.2379L14.608 91.5525L24.4233 83.0614L24.8107 83.4484L25.2628 83.9213C26.2529 85.0821 27.0063 86.0924 27.5228 86.9953Z"
                stroke="white"
              />
              <path
                d="M53.9982 82.8465L47.4762 87.6402L45.7758 85.3401L46.0556 88.672L45.2807 89.2524C43.408 90.6282 41.5569 91.4236 39.7489 91.66C38.5435 91.8105 37.295 91.7245 36.0251 91.4236C33.6574 90.7787 31.8063 89.5319 30.4287 87.6617C30.0843 87.1888 29.7184 86.5654 29.3309 85.8345C28.556 84.2652 28.1255 82.825 28.0394 81.5352C27.9533 80.2454 28.1901 78.9126 28.7498 77.4939C29.0296 76.7415 29.374 76.0966 29.7399 75.5162C30.1273 74.9573 30.4717 74.5273 30.7731 74.2694C31.0744 74.0114 31.3327 73.7965 31.5264 73.6245C31.7202 73.4525 31.9139 73.2806 32.1291 73.1301C33.5498 72.0983 35.0134 71.5608 36.5632 71.5178L36.2619 70.5935L41.9444 66.4232L49.3489 76.505L50.9417 75.3442L53.9982 82.8465ZM40.9112 78.6762C40.6744 78.3322 40.287 78.1173 39.7704 77.9883C39.2538 77.8593 38.8018 77.9023 38.4143 78.0743C38.0269 78.2462 37.7901 78.3967 37.661 78.4827C37.2735 78.7836 36.9506 79.1706 36.7354 79.6865L36.5632 80.4389C36.4771 81.1483 36.6278 81.7717 37.0152 82.3091C37.6179 83.1259 38.4143 83.4484 39.426 83.2764C39.9641 83.1904 40.9327 82.653 42.2888 81.6642L42.8484 81.2557L40.9327 78.6547L40.9112 78.6762Z"
                stroke="white"
              />
              <path
                d="M83.035 64.854L76.3839 68.8953L70.6798 59.5013C70.0556 58.4695 69.0009 58.3835 67.5587 59.2649C67.4296 59.3509 67.1928 59.5013 66.8269 59.7163L66.4179 60.1462L72.983 70.9589L66.2888 75.0003L60.6063 65.6278C60.4556 65.3914 60.2188 65.1979 59.896 65.0689C59.5731 64.9399 59.1857 64.8755 58.7121 64.8755C58.2386 64.8755 57.8081 65.0044 57.3776 65.2624C57.1623 65.3914 56.904 65.5848 56.6457 65.8428L56.3229 66.2082L62.8879 77.0209L56.1937 81.0623L49.478 70.0131L47.8206 71.0234L45.6251 63.6716L52.3193 59.6303L54.2565 62.8333L53.6753 58.792L57.8726 56.2554C58.7767 55.6965 59.939 55.5245 61.3596 55.675C62.0269 55.761 62.7372 55.9974 63.5121 56.3629C63.6843 56.4488 63.878 56.5563 64.1148 56.6853C64.2009 56.7283 64.3085 56.8143 64.4592 56.9433L63.9426 52.601L67.6233 50.3653C68.5704 49.7849 69.3883 49.4625 70.0556 49.3765C70.7444 49.2905 71.5623 49.441 72.5094 49.8279C73.1121 50.0859 73.7794 50.5588 74.4682 51.2252C74.9632 51.7411 75.3076 52.1496 75.4798 52.472L78.9238 58.1686L80.5812 57.1582L82.9919 64.854H83.035Z"
                stroke="white"
              />
              <path
                d="M88.8252 52.6655C89.4063 53.0954 90.0521 53.3319 90.7193 53.3534C91.3866 53.3748 92.2691 53.2029 93.3453 52.8159C93.4745 52.7729 93.6036 52.73 93.7112 52.687C93.8404 52.6225 93.9911 52.558 94.1202 52.472C95.1534 51.9561 96.5956 50.9672 98.4467 49.5055L99.2646 48.7961L100.771 55.9974L99.4799 56.9003C98.4467 57.5882 97.4135 58.1686 96.4234 58.663C92.7642 60.4687 89.4709 61.0276 86.522 60.2967C83.5731 59.5873 81.4207 57.8461 80.0431 55.0516C79.8709 54.6861 79.7202 54.3852 79.6341 54.1487C79.548 53.9123 79.4189 53.5468 79.2467 53.0309C78.9023 51.8056 78.7516 50.6448 78.7947 49.5915C78.9238 46.238 80.4736 43.637 83.444 41.7668C83.6377 41.6808 83.8529 41.5518 84.1112 41.4013C84.3695 41.2509 84.6278 41.1004 84.8646 40.9714C86.0054 40.4125 87.0601 40.0685 88.0503 39.9611C90.2888 39.6601 92.3552 39.9611 94.2278 40.9284C96.3157 42.0032 97.9516 43.7444 99.1355 46.1305L99.7382 47.2484L88.8252 52.644V52.6655ZM86.8234 46.453C86.3068 46.8184 85.9839 47.2914 85.8332 47.8718C85.6826 48.4522 85.7471 49.0111 86.0269 49.57L90.5686 47.3129C90.2673 46.6895 89.7938 46.2595 89.148 46.0446C88.5023 45.8296 87.8781 45.8726 87.2538 46.1735C87.0386 46.281 86.9095 46.367 86.8234 46.4315V46.453Z"
                stroke="white"
              />
              <path
                d="M144.553 39.2732L137.213 41.8742L133.532 31.513C133.123 30.3736 132.111 30.0727 130.518 30.6531C130.368 30.6961 130.109 30.8036 129.722 30.9326L129.227 31.2765L133.446 43.207L126.063 45.8081L122.404 35.4898C122.318 35.2318 122.124 34.9954 121.822 34.7804C121.543 34.587 121.155 34.4365 120.703 34.3505C120.251 34.2645 119.799 34.3075 119.326 34.458C119.089 34.544 118.809 34.6729 118.486 34.8664L118.099 35.1674L122.318 47.0979L114.935 49.699L110.608 37.5105L108.779 38.1554L108.133 30.5026L115.516 27.9016L116.764 31.4485L117.022 27.3856L121.65 25.7519C122.662 25.3865 123.824 25.451 125.18 25.9024C125.805 26.1174 126.472 26.4828 127.139 27.0202C127.29 27.1277 127.462 27.2782 127.656 27.4501C127.742 27.5146 127.828 27.6006 127.935 27.7726L128.301 23.4088L132.37 21.9685C133.403 21.6031 134.264 21.4526 134.953 21.4956C135.641 21.5601 136.416 21.8611 137.277 22.4415C137.815 22.8284 138.375 23.4088 138.913 24.2042C139.301 24.8061 139.537 25.279 139.666 25.6229L141.883 31.8999L143.713 31.255L144.488 39.2732H144.553Z"
                stroke="white"
              />
              <path
                d="M164.657 29.2128C163.731 32.2223 161.858 34.458 159.06 35.8982C158.328 36.2637 157.532 36.5646 156.649 36.8011C154.196 37.4245 151.806 37.231 149.482 36.1777C148.276 35.6188 147.265 34.9524 146.49 34.1785C145.693 33.4047 144.983 32.3728 144.316 31.083L143.885 30.0727C143.842 29.9007 143.778 29.7073 143.713 29.4923C143.648 29.2773 143.605 29.1484 143.584 29.0624C142.96 26.6978 143.261 24.2687 144.466 21.7966C145.607 19.5394 147.437 17.8627 149.934 16.8309C150.429 16.6159 150.988 16.444 151.548 16.2935C151.871 16.2075 152.129 16.143 152.301 16.1C153.528 15.7776 154.691 15.7131 155.767 15.885C156.843 16.057 158.027 16.444 159.318 17.0459C159.986 17.3468 160.545 17.7122 160.997 18.0992C162.913 19.5824 164.14 21.4311 164.721 23.6668C165.216 25.537 165.173 27.3856 164.635 29.2128H164.657ZM154.26 23.5163C153.937 23.5163 153.636 23.5593 153.335 23.6453C153.205 23.6883 153.033 23.7527 152.796 23.8602C152.129 24.1827 151.656 24.6341 151.354 25.2145C151.053 25.7949 150.988 26.4183 151.161 27.0847C151.397 28.5465 152.237 29.3633 153.636 29.5568C154.002 29.5998 154.282 29.6213 154.497 29.6213C154.626 29.6213 154.798 29.5783 155.035 29.5138C155.702 29.3418 156.24 28.9979 156.649 28.4605C157.037 27.9231 157.231 27.3212 157.209 26.6333C157.209 26.3108 157.166 26.0099 157.08 25.7089C156.714 24.3332 155.788 23.6023 154.26 23.5163Z"
                stroke="white"
              />
              <path
                d="M189.281 29.4493L181.295 30.9326L180.671 27.5146L179.896 31.1905L176.086 31.8999C175.57 31.9859 174.967 31.9644 174.278 31.8354C173.568 31.7064 172.75 31.4485 171.824 31.0615C169.37 30.0082 167.648 28.095 166.637 25.3005C166.486 24.8491 166.357 24.5481 166.292 24.3331C166.228 24.1397 166.163 23.8172 166.077 23.3873C165.926 22.5704 165.883 21.7536 165.97 20.8937C166.206 17.4973 167.455 14.9177 169.715 13.155C170.253 12.7036 170.834 12.3596 171.437 12.0802C172.039 11.8007 172.771 11.6072 173.632 11.4353C173.826 11.3923 174.041 11.3493 174.278 11.3063C174.622 11.3063 175.053 11.2633 175.591 11.1988C176.129 11.1343 176.818 11.1558 177.657 11.2633L177.442 10.081L176 10.339L176.301 2.36377L184.029 0.945007L187.882 21.7321L189.797 21.3881L189.302 29.4063L189.281 29.4493ZM179.401 20.7647C178.82 18.8515 177.765 18.0347 176.258 18.3141C175.957 18.3786 175.613 18.4646 175.247 18.5936C174.881 18.7226 174.515 19.0665 174.17 19.6254C173.826 20.1843 173.718 20.7862 173.826 21.4311C174.02 22.5059 174.515 23.1938 175.29 23.5378C176.065 23.8817 177.335 23.8817 179.121 23.5593L179.767 23.4088L179.724 22.5489C179.681 22.377 179.659 22.205 179.638 22.0545C179.616 21.9255 179.595 21.8396 179.595 21.7966C179.552 21.6246 179.53 21.4311 179.487 21.2592C179.466 21.1087 179.422 20.9367 179.401 20.7647Z"
                stroke="white"
              />
              <path
                d="M198.967 19.6899C199.376 20.2918 199.892 20.7217 200.517 20.9797C201.141 21.2377 202.023 21.3666 203.164 21.3666C203.293 21.3666 203.422 21.3666 203.552 21.3666C203.702 21.3666 203.853 21.3236 204.004 21.3236C205.166 21.1947 206.845 20.7432 209.083 20.0124L210.095 19.6254L209.062 26.9127L207.555 27.3212C206.35 27.6221 205.187 27.8156 204.09 27.9446C200.043 28.4175 196.75 27.7941 194.21 26.1389C191.67 24.4836 190.228 22.0975 189.883 19.0235C189.84 18.6151 189.819 18.2926 189.819 18.0347C189.819 17.7982 189.819 17.3898 189.819 16.8524C189.905 15.5626 190.163 14.4448 190.572 13.4559C191.821 10.3605 194.167 8.42578 197.589 7.6734C197.804 7.65191 198.041 7.60891 198.343 7.54442C198.644 7.47993 198.924 7.43694 199.204 7.41544C200.452 7.26497 201.571 7.30796 202.54 7.54442C204.757 8.01734 206.587 9.02768 208.029 10.5324C209.622 12.2521 210.59 14.4448 210.891 17.1103L211.085 18.3786L198.988 19.7544L198.967 19.6899ZM199.204 13.155C198.601 13.327 198.127 13.6709 197.804 14.1438C197.482 14.6168 197.331 15.1972 197.417 15.7991L202.454 15.2187C202.368 14.5308 202.066 13.9719 201.55 13.5419C201.033 13.112 200.409 12.9615 199.72 13.026C199.483 13.0475 199.333 13.0905 199.225 13.112L199.204 13.155Z"
                stroke="white"
              />
              <path
                d="M222.515 26.4613C221.977 26.5903 221.309 26.6763 220.513 26.7193C218.942 26.8052 217.069 26.5903 214.938 26.0959L214.077 25.8809L213.41 25.6874L212.743 25.494L214.034 18.873L215.024 19.3675L215.713 19.7114L216.036 19.8404L216.509 20.0124C216.897 20.1413 217.327 20.2488 217.758 20.3563C218.188 20.4638 218.49 20.4853 218.683 20.4853C218.985 20.4853 219.265 20.3563 219.523 20.1628C219.76 19.9694 219.889 19.7114 219.867 19.4105C219.867 19.1955 219.76 19.0235 219.566 18.8516L218.576 18.4431L216.165 17.8412C213.604 17.2178 212.248 15.6486 212.097 13.1765C212.075 12.7681 212.075 12.4456 212.097 12.2092C212.097 11.9727 212.161 11.6072 212.269 11.0698C212.484 9.95202 212.915 9.00618 213.561 8.2753C214.206 7.52292 215.11 6.89953 216.316 6.40511C217.091 6.08266 218.038 5.88919 219.157 5.82471C220.018 5.78171 220.771 5.80321 221.46 5.8677C222.149 5.95368 222.967 6.12566 223.892 6.38361C224.818 6.66307 225.743 7.02851 226.647 7.52292L225.076 13.6064C224.409 13.1765 223.849 12.8755 223.397 12.6821C222.321 12.3381 221.611 12.1662 221.266 12.1877C221.051 12.1877 220.857 12.2736 220.642 12.4456C220.427 12.5961 220.341 12.8325 220.341 13.1335C220.341 13.3485 220.448 13.5204 220.599 13.6709L220.965 13.8429L224.194 14.7887L225.076 15.1327C227.035 16.1 228.09 17.5833 228.197 19.5609C228.219 20.0983 228.197 20.6143 228.133 21.1087C227.917 22.463 227.379 23.5808 226.475 24.4836C225.571 25.365 224.258 26.0314 222.536 26.4613H222.515Z"
                stroke="white"
              />
            </svg>
          </div>
          <div
            className={cn(
              styles.circle__download,
              selectedStep === 2 ? styles.circle__about_active : ""
            )}
            onClick={() => setSelectedStep(2)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="185"
              height="90"
              viewBox="0 0 185 90"
              fill="none"
              className="animate__animated animate__rotateInDownLeft"
            >
              <path
                d="M24.3139 21.8404C23.1515 24.0975 21.5587 25.9247 19.4924 27.279C18.0287 28.2678 16.522 28.9342 14.9507 29.2567C13.3793 29.5791 11.5497 29.6436 9.48337 29.4286L1.34705 28.5688L3.43494 9.136L1.51925 8.92103L4.33897 0.859863L14.1973 1.91319C17.5336 2.27863 20.3749 3.76188 22.678 6.36295C24.0341 7.93219 24.9811 9.67341 25.4762 11.5651C25.6915 12.425 25.8206 13.3278 25.8206 14.2736C25.8206 15.2195 25.7775 16.4018 25.6269 17.8206C25.5623 18.4655 25.4116 19.1103 25.1964 19.7552C24.9811 20.4001 24.7013 21.1095 24.3354 21.8404H24.3139ZM14.6923 10.9632C13.9605 10.4903 13.078 10.2108 12.0664 10.1033L10.9255 20.7656L11.7004 20.8516C13.0995 20.8516 14.2834 20.4646 15.252 19.6048C16.2206 18.7664 16.7802 17.6056 16.9309 16.1438C16.974 15.7569 16.974 15.3485 16.9309 14.897C16.7587 13.1558 16.0054 11.8445 14.7139 10.9632H14.6923Z"
                stroke="white"
              />
              <path
                d="M45.6879 29.9875C43.5785 32.3307 40.9524 33.5775 37.8098 33.7279C36.9919 33.7709 36.1309 33.7064 35.2484 33.5345C32.7515 33.083 30.6852 31.9007 29.0063 29.966C28.1453 28.9342 27.5211 27.9239 27.1121 26.8921C26.7246 25.8602 26.4879 24.6349 26.4448 23.1732L26.4879 22.0769C26.5309 21.9049 26.5524 21.7114 26.574 21.475C26.5955 21.26 26.617 21.1095 26.6385 21.0235C27.069 18.6159 28.3605 16.5308 30.4915 14.7896C32.4933 13.2203 34.8395 12.4679 37.5516 12.5754C38.0897 12.5754 38.6708 12.6614 39.252 12.7689C39.5749 12.8334 39.8332 12.8764 40.0054 12.8979C41.2538 13.1128 42.33 13.5428 43.2341 14.1447C44.1381 14.7466 45.0637 15.6064 45.9677 16.6812C46.4413 17.2402 46.8072 17.7991 47.0439 18.3365C48.1417 20.4861 48.5076 22.6788 48.0986 24.9574C47.7542 26.8706 46.9578 28.5258 45.6879 29.966V29.9875ZM38.6278 20.4646C38.3264 20.3356 38.0466 20.2497 37.7238 20.1852C37.5946 20.1637 37.4009 20.1637 37.1426 20.1637C36.4107 20.1852 35.765 20.4001 35.2484 20.7871C34.7318 21.174 34.4089 21.7114 34.2798 22.3993C33.8924 23.8181 34.2798 24.9144 35.4852 25.6883C35.7865 25.8817 36.0448 26.0107 36.2386 26.0967C36.3677 26.1397 36.5399 26.1827 36.7551 26.2257C37.4439 26.3546 38.0681 26.2472 38.6493 25.9462C39.2305 25.6238 39.661 25.1508 39.9408 24.5274C40.0699 24.2265 40.156 23.947 40.2206 23.6246C40.4789 22.2273 39.9193 21.174 38.5847 20.4646H38.6278Z"
                stroke="white"
              />
              <path
                d="M71.8403 41.6386L64.3282 39.618L64.2206 30.2885L59.4206 38.3067L51.9085 36.286L50.8323 14.9185L58.9901 17.1112L58.8179 27.4725L64.1991 18.4869L70.269 20.1207L70.6134 30.6324L75.1982 21.4535L83.6143 23.7106L71.8619 41.6386H71.8403Z"
                stroke="white"
              />
              <path
                d="M97.0028 50.3877L89.6413 47.7436L93.4512 37.1459C93.6019 36.7374 93.5373 36.329 93.279 35.9206C93.0207 35.5121 92.5687 35.1897 91.9445 34.9747C91.7508 34.9102 91.5355 34.8457 91.3203 34.7813C91.0835 34.7383 90.8467 34.6953 90.5669 34.6738H90.1579L85.9176 46.4108L78.5562 43.7668L82.9041 31.7073L81.0745 31.0624L85.4656 24.6134L92.827 27.2575L91.6431 30.568L94.3768 27.7949L98.5741 29.2997C99.435 29.6006 100.059 29.9231 100.511 30.2455C100.942 30.5679 101.351 31.0194 101.717 31.5783C102.083 32.1587 102.319 32.7606 102.405 33.427C102.556 34.3513 102.427 35.4047 102.018 36.544L99.6934 43.0144L101.523 43.6808L97.0458 50.3877H97.0028Z"
                stroke="white"
              />
              <path
                d="M107.205 54.73L99.7363 51.5915L107.916 32.0942L106.129 31.3418L111.467 25.1078L118.398 28.0099L110.219 47.5071L112.048 48.2595L107.205 54.687V54.73Z"
                stroke="white"
              />
              <path
                d="M129.397 59.7816C126.75 61.4798 123.866 61.9957 120.809 61.2864C120.013 61.0929 119.195 60.8134 118.398 60.4265C116.117 59.3302 114.438 57.632 113.34 55.3318C112.78 54.128 112.436 52.9672 112.35 51.8709C112.242 50.7746 112.35 49.5278 112.694 48.109L113.017 47.0557C113.103 46.8837 113.168 46.7118 113.254 46.4968C113.34 46.3033 113.405 46.1529 113.426 46.0884C114.502 43.8742 116.289 42.219 118.786 41.1227C121.132 40.1339 123.586 40.0479 126.19 40.8647C126.707 41.0367 127.245 41.2517 127.783 41.5096C128.084 41.6601 128.321 41.7676 128.472 41.8536C129.613 42.391 130.538 43.1004 131.248 43.9172C131.959 44.7341 132.626 45.8089 133.207 47.0987C133.509 47.7651 133.724 48.3885 133.81 48.9689C134.305 51.3335 134.047 53.5476 133.057 55.6113C132.217 57.3525 130.99 58.7498 129.397 59.8031V59.7816ZM125.135 48.7324C124.899 48.539 124.619 48.367 124.339 48.2165C124.231 48.152 124.038 48.109 123.779 48.0446C123.069 47.8726 122.402 47.8941 121.799 48.152C121.196 48.3885 120.744 48.8184 120.443 49.4418C119.69 50.7101 119.776 51.8709 120.744 52.9243C120.981 53.1822 121.196 53.3757 121.369 53.5261C121.476 53.6121 121.627 53.6981 121.842 53.8056C122.466 54.1066 123.112 54.1925 123.758 54.0421C124.404 53.8916 124.942 53.5476 125.372 53.0102C125.566 52.7738 125.738 52.4943 125.889 52.2149C126.513 50.9466 126.255 49.7643 125.157 48.7324H125.135Z"
                stroke="white"
              />
              <path
                d="M148.188 75.9255L141.15 71.9056L142.57 69.412L139.643 71.0243L138.804 70.5513C136.78 69.3905 135.295 68.0363 134.348 66.4885C133.724 65.4567 133.293 64.2744 133.057 62.9846C132.691 60.577 133.057 58.3629 134.219 56.3637C134.52 55.8478 134.929 55.2674 135.446 54.6225C136.565 53.2897 137.706 52.3009 138.847 51.699C139.987 51.0971 141.322 50.7531 142.829 50.7101C143.625 50.6671 144.357 50.7101 145.046 50.8176C145.713 50.9466 146.23 51.0756 146.596 51.269C146.961 51.441 147.263 51.5915 147.5 51.699C147.736 51.8064 147.973 51.9139 148.188 52.0429C149.717 52.9028 150.814 54.0421 151.46 55.4393L152.192 54.7944L158.305 58.2984L152.084 69.1541L153.806 70.1429L148.188 75.9899V75.9255ZM146.682 62.2752C146.897 61.9098 146.94 61.4798 146.832 60.9424C146.725 60.4265 146.509 60.0181 146.208 59.7601C145.907 59.5022 145.67 59.3087 145.519 59.2442C145.089 59.0078 144.594 58.8788 144.034 58.8788L143.281 59.0292C142.592 59.2442 142.097 59.6312 141.752 60.2116C141.257 61.0929 141.279 61.9528 141.839 62.7911C142.14 63.264 143.022 63.9089 144.465 64.7473L145.067 65.0912L146.682 62.2752Z"
                stroke="white"
              />
              <path
                d="M167.754 88.9093L161.017 84.3736L162.954 81.493L159.833 83.5782L156.626 81.4285C156.196 81.1491 155.787 80.6977 155.378 80.1173C154.969 79.5369 154.56 78.7845 154.151 77.8601C153.139 75.4095 153.247 72.83 154.474 70.0999C154.667 69.67 154.818 69.369 154.904 69.1756C154.99 68.9821 155.162 68.7241 155.421 68.3587C155.873 67.6708 156.432 67.0474 157.1 66.4885C159.64 64.2314 162.352 63.2425 165.193 63.5865C165.903 63.651 166.549 63.8015 167.173 64.0379C167.797 64.2744 168.465 64.6183 169.197 65.1127C169.369 65.2202 169.541 65.3492 169.735 65.4782C169.993 65.7146 170.316 65.9941 170.746 66.3165C171.177 66.639 171.65 67.1549 172.167 67.7998L172.834 66.811L171.607 65.9941L177.397 60.5125L183.919 64.8978L172.124 82.4389L173.738 83.5137L167.776 88.9093H167.754ZM166.764 75.7965C167.69 74.0123 167.496 72.701 166.248 71.8626C165.989 71.6907 165.688 71.5187 165.322 71.3682C164.956 71.1962 164.461 71.1962 163.837 71.3682C163.213 71.5187 162.696 71.8841 162.33 72.4215C161.727 73.3244 161.577 74.1627 161.9 74.9366C162.223 75.732 163.127 76.6133 164.612 77.6237L165.171 77.9676L165.753 77.3227C165.86 77.1722 165.946 77.0433 166.032 76.9143C166.097 76.8068 166.162 76.7423 166.183 76.6993C166.291 76.5488 166.377 76.3984 166.484 76.2479C166.57 76.1189 166.657 75.9899 166.764 75.8395V75.7965Z"
                stroke="white"
              />
            </svg>
          </div>
          <div
            className={cn(
              styles.circle__contacts,
              selectedStep === 3 ? styles.circle__about_active : ""
            )}
            onClick={() => setSelectedStep(3)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="98"
              height="136"
              viewBox="0 0 98 136"
              fill="none"
              className="animate__animated animate__rotateInDownLeft"
            >
              <path
                d="M21.8458 17.3619L21.6305 15.9646C21.1785 13.987 20.4681 12.5467 19.5211 11.6224C19.1551 11.2569 18.7247 10.956 18.2511 10.7195C17.3686 10.2681 16.4861 10.0531 15.6036 10.0531C13.7955 10.0531 12.3964 10.5905 11.3847 11.6439C11.0188 12.0308 10.6529 12.5467 10.3085 13.1916C9.5121 14.8253 9.55514 16.5665 10.4377 18.4152C10.6959 18.9527 11.0404 19.4256 11.4709 19.8555C12.6332 20.9733 14.3337 21.5967 16.5937 21.7257H17.4332L14.6565 30.1523C13.2143 30.1308 11.7722 29.8514 10.3731 29.3354C8.95245 28.798 7.59639 28.0242 6.26186 26.9923C6.04662 26.8419 5.87442 26.6914 5.72375 26.5409C5.57308 26.4119 5.4224 26.2829 5.31477 26.1755C4.15244 25.0576 3.16234 23.6604 2.38745 22.0052C1.84933 20.9518 1.4619 19.92 1.18208 18.8882C0.92378 17.8563 0.773081 16.803 0.751556 15.7067C0.730031 14.1375 0.837639 12.8047 1.13898 11.7084C1.4188 10.612 1.93544 9.45123 2.66728 8.18294C2.96863 7.68852 3.29146 7.1941 3.63585 6.74268C3.98025 6.26976 4.30312 5.86132 4.64751 5.51737C4.92734 5.21642 5.31482 4.89398 5.76684 4.52854C6.21886 4.1631 6.71391 3.79766 7.2305 3.45372C8.19911 2.78733 9.36145 2.22842 10.6745 1.81999C12.9345 1.04612 15.2377 0.852645 17.6054 1.26108C18.0359 1.34706 18.4664 1.45455 18.9399 1.58353C19.4135 1.71251 19.8439 1.84149 20.2744 2.01346C22.0179 2.72284 23.5031 3.64719 24.687 4.80799C25.1605 5.28092 25.6556 5.79683 26.1722 6.39873L26.7534 7.1726L27.8296 6.85016L31.0368 13.6215L21.8242 17.3404L21.8458 17.3619Z"
                stroke="white"
              />
              <path
                d="M30.2619 44.9848C27.1623 45.5653 24.3211 44.9419 21.7381 43.1147C21.0708 42.6417 20.4251 42.0613 19.8224 41.3949C18.122 39.5247 17.218 37.3106 17.0673 34.7525C17.0027 33.4198 17.1319 32.2159 17.4547 31.1626C17.7776 30.1093 18.3588 28.9915 19.2198 27.8092L19.93 26.9708C20.0592 26.8418 20.2099 26.7129 20.3606 26.5624C20.5112 26.4119 20.6188 26.3044 20.6834 26.2399C22.513 24.6062 24.7946 23.7463 27.5498 23.6819C30.0897 23.6604 32.4143 24.4987 34.5022 26.2399C34.9327 26.5839 35.3417 26.9923 35.7291 27.4222C35.9444 27.6802 36.1166 27.8737 36.2457 28.0027C37.0852 28.9485 37.6879 29.9373 38.0323 30.9692C38.3767 32.001 38.5704 33.2263 38.635 34.6451C38.678 35.3759 38.635 36.0423 38.4843 36.6012C38.0538 38.9658 36.9776 40.922 35.2556 42.4698C33.8134 43.7595 32.156 44.5979 30.2834 44.9418L30.2619 44.9848ZM30.5202 33.1403C30.3695 32.8608 30.1973 32.6029 29.9821 32.3664C29.896 32.2804 29.7453 32.1515 29.53 31.9795C28.9273 31.5496 28.3031 31.3346 27.6574 31.3131C27.0117 31.3131 26.4305 31.5281 25.9139 32.001C24.73 32.8823 24.3857 34.0002 24.8592 35.3329C24.9884 35.6769 25.096 35.9348 25.2036 36.1283C25.2682 36.2358 25.3758 36.3863 25.5265 36.5582C26 37.0742 26.5597 37.3966 27.2054 37.5041C27.8511 37.6116 28.4754 37.5041 29.0996 37.1601C29.3794 37.0097 29.6377 36.8377 29.8744 36.6227C30.9291 35.6769 31.1444 34.5161 30.5202 33.1188V33.1403Z"
                stroke="white"
              />
              <path
                d="M40.8951 66.1804L36.0736 60.0324L44.9633 53.089C45.3077 52.8096 45.4798 52.4441 45.4798 51.9497C45.4798 51.4553 45.2646 50.9609 44.8556 50.4235C44.7265 50.2515 44.5758 50.101 44.4251 49.9291C44.2529 49.7571 44.0592 49.6066 43.844 49.4346L43.5211 49.1982L33.6628 56.8939L28.8413 50.7459L38.9363 42.8567L37.7525 41.3305L44.8987 38.2135L49.7202 44.3615L46.9435 46.5326L50.7319 45.6513L53.487 49.1552C54.0467 49.8646 54.4126 50.4665 54.6063 50.9824C54.8001 51.4768 54.9076 52.0787 54.9076 52.7666C54.9076 53.4545 54.7785 54.0994 54.4987 54.7013C54.1328 55.5826 53.4655 56.378 52.5184 57.1304L47.0942 61.3652L48.2781 62.8914L40.9166 66.1804H40.8951Z"
                stroke="white"
              />
              <path
                d="M58.5883 69.3188L57.7919 68.1795L47.3525 75.4883L42.7031 68.8674L53.1426 61.5586L51.5498 59.28L58.9113 56.4855L59.4063 57.1733L61.6664 55.6041L67.93 61.0857L64.0556 63.7943L65.9713 66.5243L58.6099 69.3188H58.5883Z"
                stroke="white"
              />
              <path
                d="M60.5471 96.1464L56.2637 89.2891L58.696 87.7843L55.3381 87.8273L54.8215 87.0104C53.5946 85.0327 52.9274 83.1411 52.8197 81.3139C52.7552 80.1101 52.9489 78.8633 53.3363 77.6165C54.1542 75.3164 55.5533 73.5536 57.5121 72.3283C58.0072 72.0059 58.6529 71.705 59.4278 71.361C61.0637 70.7161 62.5274 70.3937 63.8189 70.3937C65.1104 70.3937 66.4448 70.7376 67.8009 71.404C68.5327 71.748 69.1569 72.1349 69.6951 72.5433C70.2332 72.9518 70.6206 73.3387 70.8574 73.6611C71.0942 73.9836 71.2879 74.2415 71.4386 74.4565C71.5892 74.6715 71.7399 74.8649 71.8906 75.1014C72.8162 76.5846 73.2467 78.1109 73.1606 79.6371L74.1077 79.4007L77.8314 85.3767L67.1982 91.9761L68.2314 93.6528L60.5041 96.1249L60.5471 96.1464ZM65.713 83.4205C66.0574 83.2056 66.3157 82.8401 66.4879 82.3242C66.6386 81.8083 66.6601 81.3569 66.4879 80.9699C66.3372 80.583 66.2296 80.325 66.1435 80.1961C65.8852 79.7876 65.4978 79.4437 65.0027 79.1642L64.2709 78.9278C63.5821 78.7773 62.9363 78.8848 62.3767 79.2502C61.5157 79.7876 61.1282 80.5615 61.2143 81.5718C61.2574 82.1307 61.7094 83.1196 62.6134 84.5383L62.9794 85.1402L65.7345 83.442L65.713 83.4205Z"
                stroke="white"
              />
              <path
                d="M78.5633 103.197C78.6063 102.552 78.4987 102.015 78.2404 101.521C77.7453 100.596 77.0996 100.016 76.3247 99.7793C75.3777 99.5644 74.5382 99.6504 73.8279 100.037C73.0745 100.446 72.6225 101.026 72.4718 101.8C72.3211 102.574 72.4718 103.412 72.9884 104.315C73.1606 104.638 73.3974 104.938 73.6987 105.218L73.9139 105.626L68.296 110.377L67.4781 109.367C67.0906 108.851 66.7032 108.249 66.3373 107.561C65.627 106.271 65.1749 104.96 64.9812 103.606C64.7875 102.273 64.8305 100.983 65.1319 99.7578C65.7992 97.1568 67.0907 95.2006 68.9848 93.8463C69.2001 93.6743 69.5014 93.4594 69.8243 93.2659C70.1686 93.0509 70.4485 92.879 70.7068 92.75C71.6323 92.2556 72.5148 91.9331 73.3328 91.7826C74.1507 91.6537 75.1839 91.6537 76.3893 91.7826C76.7337 91.8041 77.0566 91.8471 77.3794 91.9331C77.7023 92.0191 78.0682 92.1266 78.4987 92.2771C80.931 93.1369 82.7821 94.4482 84.0736 96.2109C84.3964 96.6408 84.6763 97.0923 84.9346 97.5437C85.5804 98.7475 85.9678 100.016 86.0539 101.327L78.5633 103.176V103.197Z"
                stroke="white"
              />
              <path
                d="M84.913 115.536L84.3103 114.289L72.8592 119.878L69.3076 112.613L80.7588 107.024L79.5318 104.509L87.2376 102.896L87.6036 103.67L90.0789 102.466L95.417 108.872L91.1551 110.957L92.6188 113.945L84.913 115.558V115.536Z"
                stroke="white"
              />
              <path
                d="M77.8314 131.379C77.5301 130.928 77.2072 130.326 76.8843 129.595C76.2601 128.155 75.8081 126.327 75.5282 124.156L75.4422 123.275L75.3991 122.587L75.3561 121.899L82.0287 120.824L81.9211 121.921L81.835 122.673V123.017V123.511C81.835 123.92 81.878 124.35 81.9426 124.801C82.0072 125.231 82.0718 125.532 82.1363 125.704C82.2655 125.983 82.4592 126.22 82.7175 126.37C82.9758 126.521 83.2556 126.564 83.557 126.435C83.7507 126.349 83.8799 126.198 83.9875 125.962L84.0305 124.887L83.7722 122.437C83.4709 119.814 84.461 118.008 86.7426 117.019C87.1301 116.847 87.4314 116.74 87.6466 116.676C87.8619 116.611 88.2494 116.525 88.766 116.439C89.9068 116.267 90.9184 116.332 91.844 116.676C92.7695 117.019 93.6736 117.664 94.5561 118.589C95.1373 119.212 95.6538 120.029 96.0843 121.039C96.4287 121.813 96.6655 122.544 96.8377 123.21C97.0099 123.877 97.1175 124.715 97.1821 125.683C97.2467 126.65 97.2036 127.639 97.0745 128.671L90.8108 129.315C90.983 128.542 91.0691 127.918 91.0906 127.424C91.0476 126.284 90.9615 125.575 90.8323 125.253C90.7462 125.059 90.5956 124.887 90.3803 124.758C90.1651 124.608 89.9068 124.608 89.6269 124.737C89.4332 124.823 89.3041 124.952 89.218 125.167L89.1749 125.554L89.4117 128.907V129.853C89.1534 132.045 88.1417 133.529 86.3121 134.324C85.8171 134.539 85.322 134.69 84.8269 134.797C83.4924 135.076 82.244 134.948 81.0816 134.41C79.9408 133.873 78.8646 132.884 77.8529 131.401L77.8314 131.379Z"
                stroke="white"
              />
            </svg>
          </div>
          {selectedStep < 2 && (
            <div className={styles.circle__text}>
              {textsForMenu[selectedStep]}
            </div>
          )}
          {selectedStep === 2 && (
            <div className={styles.circle__block}>
              <div className={styles.circle__block_title}>
                Download and play now!
              </div>
              <a
                href="https://play.google.com/store/apps/details?id=com.EXTeam.HeroGuys"
                className={styles.circle__block_download}
              >
                <img src={DownloadGoogle} alt="download" />
              </a>
            </div>
          )}
          {selectedStep === 3 && (
            <>
              <div
                className={cn(
                  styles.circle__block,
                  styles.circle__block_contact
                )}
              >
                <div className={styles.circle__block_title}>Contact</div>
                <div className={styles.circle__block_wrapper}>
                  <a href="mailto:info@heroguys.io">
                    <img src={MessageIcon} alt="message" />
                  </a>
                  {/* <a href="/">
                    <img src={PhoneIcon} alt="phone" />
                  </a> */}
                </div>
              </div>
              <div className={styles.circle__block_terms}>
                <div className={styles.circle__block_terms_title}>
                  Terms of conditions
                </div>
                <div className={styles.circle__block_terms_noty}>
                  2023-Presently © On duty
                </div>
              </div>
            </>
          )}
        </div>
        <div className={styles.circle__main_mobile_footer}>
          <div className={styles.circle__main_menu}>
            <a href="https://discord.gg/J4uA3P2p2g">
              <img src={Discord} alt="discord" />
            </a>
            <a href="https://www.tiktok.com/@heroguys.io?_t=8m2MyE75liP&_r=1">
              <img src={TikTok} alt="tiktok" />
            </a>
            <a href="https://twitter.com/heroguys_io">
              <img src={X} alt="x" />
            </a>
            <a href="https://t.me/heroguys_io">
              <img src={Tg} alt="tg" />
            </a>
          </div>
          <div className={styles.circle__main_footer}>
            <div className={styles.circle__main_footer_create}>
              {/* Create by Ex2.team */}
            </div>
            <div className={styles.circle__main_footer_copy}>
              2023-Presently © On duty
            </div>
          </div>
        </div>
      </div>
      <div className={styles.circle__main_footer_create_mobile}>
        {/* Create by Ex2.team */}
      </div>
      <div className={styles.circle__main_footer_copy_mobile}>
        2023-Presently © On duty
      </div>
    </div>
  );
};

export default Menu
