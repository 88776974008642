import { lazy, ReactNode, Suspense, useMemo } from 'react'
import { Navigate, useLocation } from 'react-router-dom'

const Menu = lazy(() => import('./components/Menu/Menu'))
const Leaderboard = lazy(() => import('./components/Leaderboard/Leaderboard'))

export function useRouterConfig() {
    return useMemo(
        () => ({

        }),
        []
    )
}

function createRouteDefinition(route) {
    return {
        getElement: () => null,
        enabled: () => true,
        path: '/',
        nestedPaths: [],
        // overwrite the defaults
        ...route,
    }
}

export const routes = [
    createRouteDefinition({ path: '/', getElement: () => <Menu /> }),
    createRouteDefinition({ path: '/leaderboard', getElement: () => <Leaderboard /> }),

    createRouteDefinition({ path: '*', getElement: () => <Navigate to="/not-found" replace /> }),
    createRouteDefinition({ path: '/not-found', getElement: () => <></> }),
]
