import React from "react";
import styles from "./Modal.module.scss";
import ContactsIcon from "../../img/Contacts.svg";
import DownloadIcon from "../../img/Download.svg";

export const Modal = ({ closeAction }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.modal__wrapper}>
        <img className={styles.modal__contacts} src={ContactsIcon} alt="icon" />
        <img className={styles.modal__donwload} src={DownloadIcon} alt="icon" />
        <div onClick={closeAction}>Close</div>
      </div>
    </div>
  );
};
